<template>
    <div>
        <div class="a-flex-rfsc title">
            <span class="dot"></span>
            <span class="a-fs-14 a-ml-08">收费规则</span>
            <div class="s-tag-orange a-ml-08"><span>{{ chargePlan.type | initDic(typeDic) }}</span></div>
        </div>
        <div class="content a-flex-cfsfs a-flex-wrap a-w-100 a-c-666" v-if="chargePlan && chargePlan.type == 0">
            <span class="a-mt-20 a-fs-20 a-c-333 a-fw-700">{{ util.numFormat(chargePlan.baseAmount) }}元{{ chargePlan.baseMinutes % 60 == 0 ? (chargePlan.baseMinutes / 60 + '小时') : (chargePlan.baseMinutes + '分钟') }}</span>
            <span>加时费用：{{ util.numFormat(chargePlan.stepAmount) }}元/
                {{ chargePlan.stepMinutes % 60 == 0 ? (chargePlan.stepMinutes / 60 + '小时') : (chargePlan.stepMinutes +'分钟')}}</span>
            <span>充电时长不满{{
                chargePlan.baseMinutes % 60 == 0
                ? chargePlan.baseMinutes / 60 + "小时"
                : chargePlan.baseMinutes + "分钟"}}按{{
                    chargePlan.baseMinutes % 60 == 0
                ? chargePlan.baseMinutes / 60 + "小时"
                : chargePlan.baseMinutes + "分钟"}}计算，超过{{
                    chargePlan.baseMinutes % 60 == 0
                ? chargePlan.baseMinutes / 60 + "小时"
                : chargePlan.baseMinutes + "分钟"}}后，每{{
                    chargePlan.stepMinutes % 60 == 0
                ? (chargePlan.stepMinutes / 60) + "小时"
                : chargePlan.stepMinutes + "分钟"}}计费一次，不满{{
                    chargePlan.stepMinutes % 60 == 0
                ? (chargePlan.stepMinutes / 60) + "小时"
                : chargePlan.stepMinutes + "分钟"}}按{{
                    chargePlan.stepMinutes % 60 == 0
                ? (chargePlan.stepMinutes / 60) + "小时"
                : chargePlan.stepMinutes + "分钟"}}计算</span>
        </div>
        <div class="content a-flex-cfsfs a-flex-wrap a-w-100 a-c-666" v-else-if="chargePlan && (chargePlan.type == 1 || chargePlan.type == 6)">
            <span class="a-mt-20 a-fs-20 a-c-333 a-fw-700">{{ util.numFormat(chargePlan.baseAmount) }}元{{ chargePlan.baseMinutes % 60 == 0 ? (chargePlan.baseMinutes / 60 + '小时') : (chargePlan.baseMinutes + '分钟') }}</span>
            <span>加时费用：{{ util.numFormat(chargePlan.stepAmount) }}元/
                {{ chargePlan.stepMinutes % 60 == 0 ? (chargePlan.stepMinutes / 60 + '小时') : (chargePlan.stepMinutes +'分钟')}}</span>
            <span>充电时长不满{{
                chargePlan.baseMinutes % 60 == 0
                ? chargePlan.baseMinutes / 60 + "小时"
                : chargePlan.baseMinutes + "分钟"}}按{{
                    chargePlan.baseMinutes % 60 == 0
                ? chargePlan.baseMinutes / 60 + "小时"
                : chargePlan.baseMinutes + "分钟"}}计算，超过{{
                    chargePlan.baseMinutes % 60 == 0
                ? chargePlan.baseMinutes / 60 + "小时"
                : chargePlan.baseMinutes + "分钟"}}后，每{{
                    chargePlan.stepMinutes % 60 == 0
                ? (chargePlan.stepMinutes / 60) + "小时"
                : chargePlan.stepMinutes + "分钟"}}计费一次，不满{{
                    chargePlan.stepMinutes % 60 == 0
                ? (chargePlan.stepMinutes / 60) + "小时"
                : chargePlan.stepMinutes + "分钟"}}按{{
                    chargePlan.stepMinutes % 60 == 0
                ? (chargePlan.stepMinutes / 60) + "小时"
                : chargePlan.stepMinutes + "分钟"}}计算</span>
            <span class="a-mt-20 a-fw-700 a-c-333">功率计费：</span>
            <div style="width: 800px">
                <el-table :data="chargePlan.chargingPlanPower" :border='true' style="width: 100%">
                    <el-table-column label="功率" >
                        <template slot-scope="{ row }">
                            <span>{{row.minPower}} - {{row.maxPower}}瓦</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="address" label="起步费用" >
                        <template slot-scope="{ row }">
                            <span v-if="row.price">{{util.numFormat(row.price)}}元/{{row.minute}}分钟</span>
                            <span v-else>{{(chargePlan.baseAmount/100*row.chargeScale/100).toFixed(2)}}元/{{chargePlan.baseMinutes}}分钟</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="statusText" label="加时费用">
                        <template slot-scope="{ row }">
                            <span v-if="row.stepPrice">{{util.numFormat(row.stepPrice)}}元/{{row.stepMinute}}分钟</span>
                            <span v-else>{{(chargePlan.stepAmount/100*row.chargeScale/100).toFixed(2)}}元/{{chargePlan.stepMinutes}}分钟</span>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
        <div class="content a-flex-cfsfs a-flex-wrap a-w-100 a-c-666" v-else-if="chargePlan && chargePlan.type == 2">
            <span class="a-mt-20 a-fs-20 a-c-333 a-fw-700">{{ util.numFormatExcept10To5(chargePlan.baseAmount) }}元/kwh</span>
            <span>不满0.1kwh按0.1kwh进行计费</span>
            <span class="a-mt-20 a-fw-700 a-c-333">充电服务费：</span>
            <span class="a-mt-20 a-fs-20 a-c-333 a-fw-700">{{ util.numFormat(chargePlan.chargeBaseFee) }}元/{{ chargePlan.chargeBaseTime }}分钟</span>
            <span class="a-mt-20 a-fw-700 a-c-333" v-if="chargePlan.serviceBaseFee">服务费（占位费）：</span>
            <div class="serviceTable a-mt-10" v-if="chargePlan.serviceBaseFee" style="width: 800px">
                <div class="a-flex-rfsc a-w-100 ">
                    <span class="serviceTable-item">时间段</span>
                    <span class="serviceTable-item a-flex-1">充电时</span>
                    <span class="serviceTable-item a-flex-1">非充电时</span>
                </div>
                <div class="a-flex-rfsc a-w-100 a-fw-700" v-for="(item,index) in chargePlan.ruleList" :key="index">
                    <span class="serviceTable-item">{{ item.startTime == item.endTime ? '全天' : (item.startTime + '-' + item.endTime) }}</span>
                    <span class="serviceTable-item a-flex-1">
                        <span v-if="item.feeRuleType==1 || item.feeRuleType==3">
                            {{ util.numFormat(item.serviceFee) }}元/
                            {{ item.serviceMinutes | formatMinute }}
                        </span>
                        <span v-else>
                            {{ util.numFormat(chargePlan.serviceBaseFee) }}元/
                            {{ chargePlan.serviceBaseTime | formatMinute }}
                        </span>
                    </span>
                    <span class="serviceTable-item a-flex-1">
                        <span v-if="item.feeRuleType==2 || item.feeRuleType==3">
                            {{ util.numFormat(item.serviceFee) }}元/
                            {{ item.serviceMinutes | formatMinute }}
                        </span>
                        <span v-else>
                            {{ util.numFormat(chargePlan.serviceBaseFee) }}元/
                            {{ chargePlan.serviceBaseTime | formatMinute }}
                        </span>
                    </span>
                </div>
                <div class="a-flex-rfsc a-w-100 a-fw-700" v-if="serviceDuration > 0 && serviceDuration < 24">
                    <span class="serviceTable-item">其余时间段</span>
                    <span class="serviceTable-item a-flex-1">
                        {{ util.numFormat(chargePlan.serviceBaseFee) }}元/
                        {{ chargePlan.serviceBaseTime | formatMinute }}
                    </span>
                    <span class="serviceTable-item a-flex-1">
                        {{ util.numFormat(chargePlan.serviceBaseFee) }}元/
                        {{ chargePlan.serviceBaseTime | formatMinute }}
                    </span>
                </div>
                <div class="a-flex-rfsc a-w-100 a-fw-700" v-if="serviceDuration <= 0">
                    <span class="serviceTable-item">全天</span>
                    <span class="serviceTable-item a-flex-1">
                        {{ util.numFormat(chargePlan.serviceBaseFee) }}元/
                        {{ chargePlan.serviceBaseTime | formatMinute }}
                    </span>
                    <span class="serviceTable-item a-flex-1">
                        {{ util.numFormat(chargePlan.serviceBaseFee) }}元/
                        {{ chargePlan.serviceBaseTime | formatMinute }}
                    </span>
                </div>
            </div>
            <span v-if="chargePlan.serviceBaseFee">服务费收取：不满{{ chargePlan.serviceBaseTime | formatMinute }}，按{{ chargePlan.serviceBaseTime | formatMinute }}计算</span>
        </div>
        <div class="content a-flex-cfsfs a-flex-wrap a-w-100 a-c-666" v-else-if="chargePlan && chargePlan.type == 3">
            <span class="a-mt-20 a-fs-20 a-c-333 a-fw-700">{{ util.numFormat(chargePlan.baseAmount) }}元{{ chargePlan.baseMinutes % 60 == 0 ? (chargePlan.baseMinutes / 60 + '小时') : (chargePlan.baseMinutes + '分钟') }}</span>
            <span>加时费用：{{ util.numFormat(chargePlan.stepAmount) }}元/
                {{ chargePlan.stepMinutes % 60 == 0 ? (chargePlan.stepMinutes / 60 + '小时') : (chargePlan.stepMinutes +'分钟')}}</span>
            <span>充电时长不满{{
                chargePlan.baseMinutes % 60 == 0
                ? chargePlan.baseMinutes / 60 + "小时"
                : chargePlan.baseMinutes + "分钟"}}按{{
                    chargePlan.baseMinutes % 60 == 0
                ? chargePlan.baseMinutes / 60 + "小时"
                : chargePlan.baseMinutes + "分钟"}}计算，超过{{
                    chargePlan.baseMinutes % 60 == 0
                ? chargePlan.baseMinutes / 60 + "小时"
                : chargePlan.baseMinutes + "分钟"}}后，每{{
                    chargePlan.stepMinutes % 60 == 0
                ? (chargePlan.stepMinutes / 60) + "小时"
                : chargePlan.stepMinutes + "分钟"}}计费一次，不满{{
                    chargePlan.stepMinutes % 60 == 0
                ? (chargePlan.stepMinutes / 60) + "小时"
                : chargePlan.stepMinutes + "分钟"}}按{{
                    chargePlan.stepMinutes % 60 == 0
                ? (chargePlan.stepMinutes / 60) + "小时"
                : chargePlan.stepMinutes + "分钟"}}计算</span>
            <span class="a-mt-20 a-fw-700 a-c-333" v-if="chargePlan.serviceBaseFee">服务费（占位费）：</span>
            <div class="serviceTable a-flex-cfsfs" style="width: 800px" v-if="chargePlan.serviceBaseFee">
                <div class="a-flex-rfsc a-w-100 ">
                    <span class="serviceTable-item">时间段</span>
                    <span class="serviceTable-item a-flex-1">充电时</span>
                    <span class="serviceTable-item a-flex-1">非充电时</span>
                </div>
                <div class="a-flex-rfsc a-w-100 a-fw-700" v-for="(item,index) in chargePlan.ruleList" :key="index">
                    <span class="serviceTable-item">{{ item.startTime == item.endTime ? '全天' : (item.startTime + '-' + item.endTime) }}</span>
                    <span class="serviceTable-item a-flex-1">
                        <span v-if="item.feeRuleType==1 || item.feeRuleType==3">
                            {{ util.numFormat(item.serviceFee) }}元/
                            {{ item.serviceMinutes | formatMinute }}
                        </span>
                        <span v-else>
                            {{ util.numFormat(chargePlan.serviceBaseFee) }}元/
                            {{ chargePlan.serviceBaseTime | formatMinute }}
                        </span>
                    </span>
                    <span class="serviceTable-item a-flex-1">
                        <span v-if="item.feeRuleType==2 || item.feeRuleType==3">
                            {{ util.numFormat(item.serviceFee) }}元/
                            {{ item.serviceMinutes | formatMinute }}
                        </span>
                        <span v-else>
                            {{ util.numFormat(chargePlan.serviceBaseFee) }}元/
                            {{ chargePlan.serviceBaseTime | formatMinute }}
                        </span>
                    </span>
                </div>
                <div class="a-flex-rfsc a-w-100 a-fw-700" v-if="serviceDuration > 0 && serviceDuration < 24">
                    <span class="serviceTable-item">其余时间段</span>
                    <span class="serviceTable-item a-flex-1">
                        {{ util.numFormat(chargePlan.serviceBaseFee) }}元/
                        {{ chargePlan.serviceBaseTime | formatMinute }}
                    </span>
                    <span class="serviceTable-item a-flex-1">
                        {{ util.numFormat(chargePlan.serviceBaseFee) }}元/
                        {{ chargePlan.serviceBaseTime | formatMinute }}
                    </span>
                </div>
                <div class="a-flex-rfsc a-w-100 a-fw-700" v-if="serviceDuration <= 0">
                    <span class="serviceTable-item">全天</span>
                    <span class="serviceTable-item a-flex-1">
                        {{ util.numFormat(chargePlan.serviceBaseFee) }}元/
                        {{ chargePlan.serviceBaseTime | formatMinute }}
                    </span>
                    <span class="serviceTable-item a-flex-1">
                        {{ util.numFormat(chargePlan.serviceBaseFee) }}元/
                        {{ chargePlan.serviceBaseTime | formatMinute }}
                    </span>
                </div>
            </div>
            <span v-if="chargePlan.serviceBaseFee">服务费收取：不满{{ chargePlan.serviceBaseTime | formatMinute }}，按{{ chargePlan.serviceBaseTime | formatMinute }}计算</span>
        </div>
        <div class="content a-flex-cfsfs a-flex-wrap a-w-100 a-c-666" v-else-if="chargePlan && (chargePlan.type == 4 || chargePlan.type == 8)">
            <span class="a-mt-20 a-fs-20 a-c-333 a-fw-700">{{ util.numFormat(chargePlan.baseAmount) }}元{{ chargePlan.baseMinutes % 60 == 0 ? (chargePlan.baseMinutes / 60 + '小时') : (chargePlan.baseMinutes + '分钟') }}</span>
            <span>加时费用：{{ util.numFormat(chargePlan.stepAmount) }}元/
                {{ chargePlan.stepMinutes % 60 == 0 ? (chargePlan.stepMinutes / 60 + '小时') : (chargePlan.stepMinutes +'分钟')}}</span>
            <span>充电时长不满{{
                chargePlan.baseMinutes % 60 == 0
                ? chargePlan.baseMinutes / 60 + "小时"
                : chargePlan.baseMinutes + "分钟"}}按{{
                    chargePlan.baseMinutes % 60 == 0
                ? chargePlan.baseMinutes / 60 + "小时"
                : chargePlan.baseMinutes + "分钟"}}计算，超过{{
                    chargePlan.baseMinutes % 60 == 0
                ? chargePlan.baseMinutes / 60 + "小时"
                : chargePlan.baseMinutes + "分钟"}}后，每{{
                    chargePlan.stepMinutes % 60 == 0
                ? (chargePlan.stepMinutes / 60) + "小时"
                : chargePlan.stepMinutes + "分钟"}}计费一次，不满{{
                    chargePlan.stepMinutes % 60 == 0
                ? (chargePlan.stepMinutes / 60) + "小时"
                : chargePlan.stepMinutes + "分钟"}}按{{
                    chargePlan.stepMinutes % 60 == 0
                ? (chargePlan.stepMinutes / 60) + "小时"
                : chargePlan.stepMinutes + "分钟"}}计算</span>
            <span class="a-mt-20 a-fw-700 a-c-333">功率计费：</span>
            <div style="width: 800px">
                <el-table :data="chargePlan.chargingPlanPower" :border='true' style="width: 100%">
                    <el-table-column label="功率" >
                        <template slot-scope="{ row }">
                            <span>{{row.minPower}} - {{row.maxPower}}瓦</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="address" label="起步费用" >
                        <template slot-scope="{ row }">
                            <span v-if="row.price">{{util.numFormat(row.price)}}元/{{row.minute}}分钟</span>
                            <span v-else>{{(chargePlan.baseAmount/100*row.chargeScale/100).toFixed(2)}}元/{{chargePlan.baseMinutes}}分钟</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="statusText" label="加时费用">
                        <template slot-scope="{ row }">
                            <span v-if="row.stepPrice">{{util.numFormat(row.stepPrice)}}元/{{row.stepMinute}}分钟</span>
                            <span v-else>{{(chargePlan.stepAmount/100*row.chargeScale/100).toFixed(2)}}元/{{chargePlan.stepMinutes}}分钟</span>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <span class="a-mt-20 a-fw-700 a-c-333" v-if="chargePlan.serviceBaseFee">服务费（占位费）：</span>
            <div class="serviceTable a-flex-cfsfs" style="width: 800px" v-if="chargePlan.serviceBaseFee">
                <div class="a-flex-rfsc a-w-100 ">
                    <span class="serviceTable-item">时间段</span>
                    <span class="serviceTable-item a-flex-1">充电时</span>
                    <span class="serviceTable-item a-flex-1">非充电时</span>
                </div>
                <div class="a-flex-rfsc a-w-100 a-fw-700" v-for="(item,index) in chargePlan.ruleList" :key="index">
                    <span class="serviceTable-item">{{ item.startTime == item.endTime ? '全天' : (item.startTime + '-' + item.endTime) }}</span>
                    <span class="serviceTable-item a-flex-1">
                        <span v-if="item.feeRuleType==1 || item.feeRuleType==3">
                            {{ util.numFormat(item.serviceFee) }}元/
                            {{ item.serviceMinutes | formatMinute }}
                        </span>
                        <span v-else>
                            {{ util.numFormat(chargePlan.serviceBaseFee) }}元/
                            {{ chargePlan.serviceBaseTime | formatMinute }}
                        </span>
                    </span>
                    <span class="serviceTable-item a-flex-1">
                        <span v-if="item.feeRuleType==2 || item.feeRuleType==3">
                            {{ util.numFormat(item.serviceFee) }}元/
                            {{ item.serviceMinutes | formatMinute }}
                        </span>
                        <span v-else>
                            {{ util.numFormat(chargePlan.serviceBaseFee) }}元/
                            {{ chargePlan.serviceBaseTime | formatMinute }}
                        </span>
                    </span>
                </div>
                <div class="a-flex-rfsc a-w-100 a-fw-700" v-if="serviceDuration > 0 && serviceDuration < 24">
                    <span class="serviceTable-item">其余时间段</span>
                    <span class="serviceTable-item a-flex-1">
                        {{ util.numFormat(chargePlan.serviceBaseFee) }}元/
                        {{ chargePlan.serviceBaseTime | formatMinute }}
                    </span>
                    <span class="serviceTable-item a-flex-1">
                        {{ util.numFormat(chargePlan.serviceBaseFee) }}元/
                        {{ chargePlan.serviceBaseTime | formatMinute }}
                    </span>
                </div>
                <div class="a-flex-rfsc a-w-100 a-fw-700" v-if="serviceDuration <= 0">
                    <span class="serviceTable-item">全天</span>
                    <span class="serviceTable-item a-flex-1">
                        {{ util.numFormat(chargePlan.serviceBaseFee) }}元/
                        {{ chargePlan.serviceBaseTime | formatMinute }}
                    </span>
                    <span class="serviceTable-item a-flex-1">
                        {{ util.numFormat(chargePlan.serviceBaseFee) }}元/
                        {{ chargePlan.serviceBaseTime | formatMinute }}
                    </span>
                </div>
            </div>
            <span v-if="chargePlan.serviceBaseFee">服务费收取：不满{{ chargePlan.serviceBaseTime | formatMinute }}，按{{ chargePlan.serviceBaseTime | formatMinute }}计算</span>
        </div>
        <div class="content a-flex-cfsfs a-flex-wrap a-w-100 a-c-666" v-else-if="chargePlan && chargePlan.type == 5">
            <span class="a-mt-20 a-fs-20 a-c-333 a-fw-700">{{ util.numFormatExcept10To5(chargePlan.baseAmount) }}元/kwh</span>
            <div v-if="Number(chargePlan.baseChoice) || Number(chargePlan.overChoice)">
                <span v-if="chargePlan.baseChoice == chargePlan.overChoice || !Number(chargePlan.baseChoice) || !Number(chargePlan.overChoice)">
                    用电量不足{{ Number(chargePlan.baseChoice) || Number(chargePlan.overChoice) }}kwh按{{ Number(chargePlan.baseChoice) || Number(chargePlan.overChoice) }}kwh计算
                </span>
                <span v-else>
                    用电量不足{{ chargePlan.baseChoice }}kwh按{{ chargePlan.baseChoice }}kwh计算，超出部分按每{{ chargePlan.overChoice }}kwh计算电量，不足{{ chargePlan.overChoice }}kwh的部分按照{{ chargePlan.overChoice }}kwh计算电量
                </span>
            </div>
            <span class="a-mt-20 a-fw-700 a-c-333">充电服务费：</span>
            <span class="a-mt-20 a-fs-20 a-c-333 a-fw-700">{{ util.numFormatExcept10To5(chargePlan.chargeBaseFee) }}元/kwh</span>
            <div v-if="Number(chargePlan.baseChoice) || Number(chargePlan.overChoice)">
                <span v-if="chargePlan.baseChoice == chargePlan.overChoice || !Number(chargePlan.baseChoice) || !Number(chargePlan.overChoice)">
                    用电量不足{{ Number(chargePlan.baseChoice) || Number(chargePlan.overChoice) }}kwh按{{ Number(chargePlan.baseChoice) || Number(chargePlan.overChoice) }}kwh计算
                </span>
                <span v-else>
                    用电量不足{{ chargePlan.baseChoice }}kwh按{{ chargePlan.baseChoice }}kwh计算，超出部分按每{{ chargePlan.overChoice }}kwh计算电量，不足{{ chargePlan.overChoice }}kwh的部分按照{{ chargePlan.overChoice }}kwh计算电量
                </span>
            </div>
            <span class="a-mt-20 a-fw-700 a-c-333" v-if="chargePlan.serviceBaseFee">服务费（占位费）：</span>
            <div class="serviceTable a-mt-10" v-if="chargePlan.serviceBaseFee" style="width: 800px">
                <div class="a-flex-rfsc a-w-100 ">
                    <span class="serviceTable-item">时间段</span>
                    <span class="serviceTable-item a-flex-1">充电时</span>
                    <span class="serviceTable-item a-flex-1">非充电时</span>
                </div>
                <div class="a-flex-rfsc a-w-100 a-fw-700" v-for="(item,index) in chargePlan.ruleList" :key="index">
                    <span class="serviceTable-item">{{ item.startTime == item.endTime ? '全天' : (item.startTime + '-' + item.endTime) }}</span>
                    <span class="serviceTable-item a-flex-1">
                        <span v-if="item.feeRuleType==1 || item.feeRuleType==3">
                            {{ util.numFormat(item.serviceFee) }}元/
                            {{ item.serviceMinutes | formatMinute }}
                        </span>
                        <span v-else>
                            {{ util.numFormat(chargePlan.serviceBaseFee) }}元/
                            {{ chargePlan.serviceBaseTime | formatMinute }}
                        </span>
                    </span>
                    <span class="serviceTable-item a-flex-1">
                        <span v-if="item.feeRuleType==2 || item.feeRuleType==3">
                            {{ util.numFormat(item.serviceFee) }}元/
                            {{ item.serviceMinutes | formatMinute }}
                        </span>
                        <span v-else>
                            {{ util.numFormat(chargePlan.serviceBaseFee) }}元/
                            {{ chargePlan.serviceBaseTime | formatMinute }}
                        </span>
                    </span>
                </div>
                <div class="a-flex-rfsc a-w-100 a-fw-700" v-if="serviceDuration > 0 && serviceDuration < 24">
                    <span class="serviceTable-item">其余时间段</span>
                    <span class="serviceTable-item a-flex-1">
                        {{ util.numFormat(chargePlan.serviceBaseFee) }}元/
                        {{ chargePlan.serviceBaseTime | formatMinute }}
                    </span>
                    <span class="serviceTable-item a-flex-1">
                        {{ util.numFormat(chargePlan.serviceBaseFee) }}元/
                        {{ chargePlan.serviceBaseTime | formatMinute }}
                    </span>
                </div>
                <div class="a-flex-rfsc a-w-100 a-fw-700" v-if="serviceDuration <= 0">
                    <span class="serviceTable-item">全天</span>
                    <span class="serviceTable-item a-flex-1">
                        {{ util.numFormat(chargePlan.serviceBaseFee) }}元/
                        {{ chargePlan.serviceBaseTime | formatMinute }}
                    </span>
                    <span class="serviceTable-item a-flex-1">
                        {{ util.numFormat(chargePlan.serviceBaseFee) }}元/
                        {{ chargePlan.serviceBaseTime | formatMinute }}
                    </span>
                </div>
            </div>
            <span v-if="chargePlan.serviceBaseFee">服务费收取：不满{{ chargePlan.serviceBaseTime | formatMinute }}，按{{ chargePlan.serviceBaseTime | formatMinute }}计算</span>
        </div>
        <div class="content a-flex-cfsfs a-flex-wrap a-w-100 a-c-666" v-else-if="chargePlan && chargePlan.type == 7">
            <span class="a-mt-20 a-fs-20 a-c-333 a-fw-700">{{ util.numFormatExcept10To5(chargePlan.baseAmount) }}元/kwh</span>
            <span>不满0.1kwh按0.1kwh进行计费</span>
            <span class="a-mt-20 a-fw-700 a-c-333" v-if="chargePlan.powerRuleList.length>0">充电服务费：</span>
            <div v-if="chargePlan.powerRuleList.length>0" class="a-mt-24">
                <el-table :data="chargePlan.powerRuleList" :highlight-current-row="true" style="width: 800px">
                    <el-table-column label="功率" >
                        <template slot-scope="{ row }">
                            <span>{{row.minPower}} - {{row.maxPower}}瓦</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="address" label="费用" >
                        <template slot-scope="{ row }">
                            <span>{{ util.numFormat(row.price) }}元/{{ row.minute }}分钟</span>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <span class="a-mt-20 a-fw-700 a-c-333" v-if="chargePlan.serviceBaseFee">服务费（占位费）：</span>
            <div class="serviceTable a-mt-10" style="width: 800px" v-if="chargePlan.serviceBaseFee">
                <div class="a-flex-rfsc a-w-100 ">
                    <span class="serviceTable-item">时间段</span>
                    <span class="serviceTable-item a-flex-1">充电时</span>
                    <span class="serviceTable-item a-flex-1">非充电时</span>
                </div>
                <div class="a-flex-rfsc a-w-100 a-fw-700" v-for="(item,index) in chargePlan.ruleList" :key="index">
                    <span class="serviceTable-item">{{ item.startTime == item.endTime ? '全天' : (item.startTime + '-' + item.endTime) }}</span>
                    <!-- <span class="serviceTable-item a-flex-1">按功率计费</span> -->
                    <span class="serviceTable-item a-flex-1">
                        <span v-if="item.feeRuleType==1 || item.feeRuleType==3">
                            {{ util.numFormat(item.serviceFee) }}元/
                            {{ item.serviceMinutes | formatMinute }}
                        </span>
                        <span v-else>
                            {{ util.numFormat(chargePlan.serviceBaseFee) }}元/
                            {{ chargePlan.serviceBaseTime | formatMinute }}
                        </span>
                    </span>
                    <span class="serviceTable-item a-flex-1">
                        <span v-if="item.feeRuleType==2 || item.feeRuleType==3">
                            {{ util.numFormat(item.serviceFee) }}元/
                            {{ item.serviceMinutes | formatMinute }}
                        </span>
                        <span v-else>
                            {{ util.numFormat(chargePlan.serviceBaseFee) }}元/
                            {{ chargePlan.serviceBaseTime | formatMinute }}
                        </span>
                    </span>
                </div>
                <div class="a-flex-rfsc a-w-100 a-fw-700" v-if="serviceDuration > 0 && serviceDuration < 24">
                    <span class="serviceTable-item">其余时间段</span>
                    <!-- <span class="serviceTable-item a-flex-1">按功率计费</span> -->
                    <span class="serviceTable-item a-flex-1">
                        {{ util.numFormat(chargePlan.serviceBaseFee) }}元/
                        {{ chargePlan.serviceBaseTime | formatMinute }}
                    </span>
                    <span class="serviceTable-item a-flex-1">
                        {{ util.numFormat(chargePlan.serviceBaseFee) }}元/
                        {{ chargePlan.serviceBaseTime | formatMinute }}
                    </span>
                </div>
                <div class="a-flex-rfsc a-w-100 a-fw-700" v-if="serviceDuration <= 0">
                    <span class="serviceTable-item">全天</span>
                    <!-- <span class="serviceTable-item a-flex-1">按功率计费</span> -->
                    <span class="serviceTable-item a-flex-1">
                        {{ util.numFormat(chargePlan.serviceBaseFee) }}元/
                        {{ chargePlan.serviceBaseTime | formatMinute }}
                    </span>
                    <span class="serviceTable-item a-flex-1">
                        {{ util.numFormat(chargePlan.serviceBaseFee) }}元/
                        {{ chargePlan.serviceBaseTime | formatMinute }}
                    </span>
                </div>
            </div>
            <span v-if="chargePlan.serviceBaseFee">服务费收取：不满{{ chargePlan.serviceBaseTime | formatMinute }}，按{{ chargePlan.serviceBaseTime | formatMinute }}计算</span>
        </div>
        <div class="content a-flex-cfsfs a-flex-wrap a-w-100 a-c-666" v-else-if="chargePlan && chargePlan.type == 9">
            <span class="a-mt-20 a-fs-20 a-c-333 a-fw-700">{{ util.numFormatExcept10To5(chargePlan.baseAmount) }}元/kwh</span>
            <span>不满0.1kwh按0.1kwh进行计费</span>
            <span class="a-mt-20 a-fw-700 a-c-333" v-if="chargePlan.serviceBaseFee">服务费（占位费）：</span>
            <div class="serviceTable a-mt-10" style="width: 800px" v-if="chargePlan.serviceBaseFee">
                <div class="a-flex-rfsc a-w-100 ">
                    <span class="serviceTable-item">时间段</span>
                    <span class="serviceTable-item a-flex-1">充电时</span>
                    <span class="serviceTable-item a-flex-1">非充电时</span>
                </div>
                <div class="a-flex-rfsc a-w-100 a-fw-700" v-for="(item,index) in chargePlan.ruleList" :key="index">
                    <span class="serviceTable-item">{{ item.startTime == item.endTime ? '全天' : (item.startTime + '-' + item.endTime) }}</span>
                    <!-- <span class="serviceTable-item a-flex-1">按功率计费</span> -->
                    <span class="serviceTable-item a-flex-1">
                        <span v-if="item.feeRuleType==1 || item.feeRuleType==3">
                            {{ util.numFormat(item.serviceFee) }}元/
                            {{ item.serviceMinutes | formatMinute }}
                        </span>
                        <span v-else>
                            {{ util.numFormat(chargePlan.serviceBaseFee) }}元/
                            {{ chargePlan.serviceBaseTime | formatMinute }}
                        </span>
                    </span>
                    <span class="serviceTable-item a-flex-1">
                        <span v-if="item.feeRuleType==2 || item.feeRuleType==3">
                            {{ util.numFormat(item.serviceFee) }}元/
                            {{ item.serviceMinutes | formatMinute }}
                        </span>
                        <span v-else>
                            {{ util.numFormat(chargePlan.serviceBaseFee) }}元/
                            {{ chargePlan.serviceBaseTime | formatMinute }}
                        </span>
                    </span>
                </div>
                <div class="a-flex-rfsc a-w-100 a-fw-700" v-if="serviceDuration > 0 && serviceDuration < 24">
                    <span class="serviceTable-item">其余时间段</span>
                    <!-- <span class="serviceTable-item a-flex-1">按功率计费</span> -->
                    <span class="serviceTable-item a-flex-1">
                        {{ util.numFormat(chargePlan.serviceBaseFee) }}元/
                        {{ chargePlan.serviceBaseTime | formatMinute }}
                    </span>
                    <span class="serviceTable-item a-flex-1">
                        {{ util.numFormat(chargePlan.serviceBaseFee) }}元/
                        {{ chargePlan.serviceBaseTime | formatMinute }}
                    </span>
                </div>
                <div class="a-flex-rfsc a-w-100 a-fw-700" v-if="serviceDuration <= 0">
                    <span class="serviceTable-item">全天</span>
                    <!-- <span class="serviceTable-item a-flex-1">按功率计费</span> -->
                    <span class="serviceTable-item a-flex-1">
                        {{ util.numFormat(chargePlan.serviceBaseFee) }}元/
                        {{ chargePlan.serviceBaseTime | formatMinute }}
                    </span>
                    <span class="serviceTable-item a-flex-1">
                        {{ util.numFormat(chargePlan.serviceBaseFee) }}元/
                        {{ chargePlan.serviceBaseTime | formatMinute }}
                    </span>
                </div>
            </div>
            <span v-if="chargePlan.serviceBaseFee">服务费收取：不满{{ chargePlan.serviceBaseTime | formatMinute }}，按{{ chargePlan.serviceBaseTime | formatMinute }}计算</span>
        </div>
        <div class="content a-flex-cfsfs a-flex-wrap a-w-100 a-c-666" v-else-if="chargePlan && chargePlan.type == 10">
            <span class="a-mt-20 a-fs-20 a-c-333 a-fw-700">{{ util.numFormatExcept10To5(chargePlan.baseAmount) }}元/kwh</span>
            <div v-if="Number(chargePlan.baseChoice) || Number(chargePlan.overChoice)">
                <span v-if="chargePlan.baseChoice == chargePlan.overChoice || !Number(chargePlan.baseChoice) || !Number(chargePlan.overChoice)">
                    用电量不足{{ Number(chargePlan.baseChoice) || Number(chargePlan.overChoice) }}kwh按{{ Number(chargePlan.baseChoice) || Number(chargePlan.overChoice) }}kwh计算
                </span>
                <span v-else>
                    用电量不足{{ chargePlan.baseChoice }}kwh按{{ chargePlan.baseChoice }}kwh计算，超出部分按每{{ chargePlan.overChoice }}kwh计算电量，不足{{ chargePlan.overChoice }}kwh的部分按照{{ chargePlan.overChoice }}kwh计算电量
                </span>
            </div>
            <span class="a-mt-20 a-fw-700 a-c-333">充电服务费：</span>
            <span class="a-mt-20 a-fs-20 a-c-333 a-fw-700">{{ util.numFormatExcept10To5(chargePlan.chargeBaseFee) }}元/kwh</span>
            <div v-if="Number(chargePlan.baseChoice) || Number(chargePlan.overChoice)">
                <span v-if="chargePlan.baseChoice == chargePlan.overChoice || !Number(chargePlan.baseChoice) || !Number(chargePlan.overChoice)">
                    用电量不足{{ Number(chargePlan.baseChoice) || Number(chargePlan.overChoice) }}kwh按{{ Number(chargePlan.baseChoice) || Number(chargePlan.overChoice) }}kwh计算
                </span>
                <span v-else>
                    用电量不足{{ chargePlan.baseChoice }}kwh按{{ chargePlan.baseChoice }}kwh计算，超出部分按每{{ chargePlan.overChoice }}kwh计算电量，不足{{ chargePlan.overChoice }}kwh的部分按照{{ chargePlan.overChoice }}kwh计算电量
                </span>
            </div>
            <span class="a-mt-20 a-fw-700 a-c-333" v-if="chargePlan.serviceBaseFee">服务费（占位费）：</span>
            <div class="serviceTable a-mt-10" style="width: 800px" v-if="chargePlan.serviceBaseFee">
                <div class="a-flex-rfsc a-w-100 ">
                    <span class="serviceTable-item">时间段</span>
                    <span class="serviceTable-item a-flex-1">充电时</span>
                    <span class="serviceTable-item a-flex-1">非充电时</span>
                </div>
                <div class="a-flex-rfsc a-w-100 a-fw-700" v-for="(item,index) in chargePlan.ruleList" :key="index">
                    <span class="serviceTable-item">{{ item.startTime == item.endTime ? '全天' : (item.startTime + '-' + item.endTime) }}</span>
                    <!-- <span class="serviceTable-item a-flex-1">按功率计费</span> -->
                    <span class="serviceTable-item a-flex-1">
                        <span v-if="item.feeRuleType==1 || item.feeRuleType==3">
                            {{ util.numFormat(item.serviceFee) }}元/
                            {{ item.serviceMinutes | formatMinute }}
                        </span>
                        <span v-else>
                            {{ util.numFormat(chargePlan.serviceBaseFee) }}元/
                            {{ chargePlan.serviceBaseTime | formatMinute }}
                        </span>
                    </span>
                    <span class="serviceTable-item a-flex-1">
                        <span v-if="item.feeRuleType==2 || item.feeRuleType==3">
                            {{ util.numFormat(item.serviceFee) }}元/
                            {{ item.serviceMinutes | formatMinute }}
                        </span>
                        <span v-else>
                            {{ util.numFormat(chargePlan.serviceBaseFee) }}元/
                            {{ chargePlan.serviceBaseTime | formatMinute }}
                        </span>
                    </span>
                </div>
                <div class="a-flex-rfsc a-w-100 a-fw-700" v-if="serviceDuration > 0 && serviceDuration < 24">
                    <span class="serviceTable-item">其余时间段</span>
                    <!-- <span class="serviceTable-item a-flex-1">按功率计费</span> -->
                    <span class="serviceTable-item a-flex-1">
                        {{ util.numFormat(chargePlan.serviceBaseFee) }}元/
                        {{ chargePlan.serviceBaseTime | formatMinute }}
                    </span>
                    <span class="serviceTable-item a-flex-1">
                        {{ util.numFormat(chargePlan.serviceBaseFee) }}元/
                        {{ chargePlan.serviceBaseTime | formatMinute }}
                    </span>
                </div>
                <div class="a-flex-rfsc a-w-100 a-fw-700" v-if="serviceDuration <= 0">
                    <span class="serviceTable-item">全天</span>
                    <!-- <span class="serviceTable-item a-flex-1">按功率计费</span> -->
                    <span class="serviceTable-item a-flex-1">
                        {{ util.numFormat(chargePlan.serviceBaseFee) }}元/
                        {{ chargePlan.serviceBaseTime | formatMinute }}
                    </span>
                    <span class="serviceTable-item a-flex-1">
                        {{ util.numFormat(chargePlan.serviceBaseFee) }}元/
                        {{ chargePlan.serviceBaseTime | formatMinute }}
                    </span>
                </div>
            </div>
            <span v-if="chargePlan.serviceBaseFee">服务费收取：不满{{ chargePlan.serviceBaseTime | formatMinute }}，按{{ chargePlan.serviceBaseTime | formatMinute }}计算</span>
        </div>
        <div class="content a-flex-cfsfs a-flex-wrap a-w-100 a-c-666" v-else-if="chargePlan && chargePlan.type == 11">
            <span class="a-mt-20 a-fs-20 a-c-333 a-fw-700">{{ util.numFormatExcept10To5(chargePlan.baseAmount) }}元/kwh</span>
            <div v-if="Number(chargePlan.baseChoice) || Number(chargePlan.overChoice)">
                <span v-if="chargePlan.baseChoice == chargePlan.overChoice || !Number(chargePlan.baseChoice) || !Number(chargePlan.overChoice)">
                    用电量不足{{ Number(chargePlan.baseChoice) || Number(chargePlan.overChoice) }}kwh按{{ Number(chargePlan.baseChoice) || Number(chargePlan.overChoice) }}kwh计算
                </span>
                <span v-else>
                    用电量不足{{ chargePlan.baseChoice }}kwh按{{ chargePlan.baseChoice }}kwh计算，超出部分按每{{ chargePlan.overChoice }}kwh计算电量，不足{{ chargePlan.overChoice }}kwh的部分按照{{ chargePlan.overChoice }}kwh计算电量
                </span>
            </div>
            <span class="a-mt-20 a-fw-700 a-c-333">充电服务费：</span>
            <span class="a-mt-20 a-fs-20 a-c-333 a-fw-700">{{ util.numFormatExcept10To5(chargePlan.chargeBaseFee) }}元/kwh</span>
            <div v-if="Number(chargePlan.baseChoice) || Number(chargePlan.overChoice)">
                <span v-if="chargePlan.baseChoice == chargePlan.overChoice || !Number(chargePlan.baseChoice) || !Number(chargePlan.overChoice)">
                    用电量不足{{ Number(chargePlan.baseChoice) || Number(chargePlan.overChoice) }}kwh按{{ Number(chargePlan.baseChoice) || Number(chargePlan.overChoice) }}kwh计算
                </span>
                <span v-else>
                    用电量不足{{ chargePlan.baseChoice }}kwh按{{ chargePlan.baseChoice }}kwh计算，超出部分按每{{ chargePlan.overChoice }}kwh计算电量，不足{{ chargePlan.overChoice }}kwh的部分按照{{ chargePlan.overChoice }}kwh计算电量
                </span>
            </div>
            <span class="a-mt-20 a-fw-700 a-c-333" v-if="chargePlan.serviceBaseFee">服务费（占位费）：</span>
            <div class="serviceTable a-mt-10" style="width: 800px" v-if="chargePlan.serviceBaseFee">
                <div class="a-flex-rfsc a-w-100 ">
                    <span class="serviceTable-item">时间段</span>
                    <span class="serviceTable-item a-flex-1">充电时</span>
                    <span class="serviceTable-item a-flex-1">非充电时</span>
                </div>
                <div class="a-flex-rfsc a-w-100 a-fw-700" v-for="(item,index) in chargePlan.ruleList" :key="index">
                    <span class="serviceTable-item">{{ item.startTime == item.endTime ? '全天' : (item.startTime + '-' + item.endTime) }}</span>
                    <!-- <span class="serviceTable-item a-flex-1">按功率计费</span> -->
                    <span class="serviceTable-item a-flex-1">
                        <span v-if="item.feeRuleType==1 || item.feeRuleType==3">
                            {{ util.numFormat(item.serviceFee) }}元/
                            {{ item.serviceMinutes | formatMinute }}
                        </span>
                        <span v-else>
                            {{ util.numFormat(chargePlan.serviceBaseFee) }}元/
                            {{ chargePlan.serviceBaseTime | formatMinute }}
                        </span>
                    </span>
                    <span class="serviceTable-item a-flex-1">
                        <span v-if="item.feeRuleType==2 || item.feeRuleType==3">
                            {{ util.numFormat(item.serviceFee) }}元/
                            {{ item.serviceMinutes | formatMinute }}
                        </span>
                        <span v-else>
                            {{ util.numFormat(chargePlan.serviceBaseFee) }}元/
                            {{ chargePlan.serviceBaseTime | formatMinute }}
                        </span>
                    </span>
                </div>
                <div class="a-flex-rfsc a-w-100 a-fw-700" v-if="serviceDuration > 0 && serviceDuration < 24">
                    <span class="serviceTable-item">其余时间段</span>
                    <!-- <span class="serviceTable-item a-flex-1">按功率计费</span> -->
                    <span class="serviceTable-item a-flex-1">
                        {{ util.numFormat(chargePlan.serviceBaseFee) }}元/
                        {{ chargePlan.serviceBaseTime | formatMinute }}
                    </span>
                    <span class="serviceTable-item a-flex-1">
                        {{ util.numFormat(chargePlan.serviceBaseFee) }}元/
                        {{ chargePlan.serviceBaseTime | formatMinute }}
                    </span>
                </div>
                <div class="a-flex-rfsc a-w-100 a-fw-700" v-if="serviceDuration <= 0">
                    <span class="serviceTable-item">全天</span>
                    <!-- <span class="serviceTable-item a-flex-1">按功率计费</span> -->
                    <span class="serviceTable-item a-flex-1">
                        {{ util.numFormat(chargePlan.serviceBaseFee) }}元/
                        {{ chargePlan.serviceBaseTime | formatMinute }}
                    </span>
                    <span class="serviceTable-item a-flex-1">
                        {{ util.numFormat(chargePlan.serviceBaseFee) }}元/
                        {{ chargePlan.serviceBaseTime | formatMinute }}
                    </span>
                </div>
            </div>
            <span v-if="chargePlan.serviceBaseFee">服务费收取：不满{{ chargePlan.serviceBaseTime | formatMinute }}，按{{ chargePlan.serviceBaseTime | formatMinute }}计算</span>
        </div>
        <div class="content a-flex-cfsfs a-flex-wrap a-w-100 a-c-666" v-else-if="chargePlan && chargePlan.type == 12">
            <span class="a-mt-20 a-fs-20 a-c-333 a-fw-700">{{ util.numFormatExcept10To5(chargePlan.baseAmount) }}元/kwh</span>
            <span>不满0.1kwh按0.1kwh进行计费</span>
            <span class="a-mt-20 a-fw-700 a-c-333" v-if="chargePlan.powerRuleList.length>0">充电服务费：</span>
            <div v-if="chargePlan.powerRuleList.length>0" class="a-mt-24">
                <el-table :data="chargePlan.powerRuleList" :highlight-current-row="true" style="width: 800px">
                    <el-table-column label="功率" >
                        <template slot-scope="{ row }">
                            <span>{{row.minPower}} - {{row.maxPower}}瓦</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="address" label="费用" >
                        <template slot-scope="{ row }">
                            <span>{{ util.numFormat(row.price) }}元/{{ row.minute }}分钟</span>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <span class="a-mt-20 a-fw-700 a-c-333" v-if="chargePlan.serviceBaseFee">服务费（占位费）：</span>
            <div class="serviceTable a-mt-10" style="width: 800px" v-if="chargePlan.serviceBaseFee">
                <div class="a-flex-rfsc a-w-100 ">
                    <span class="serviceTable-item">时间段</span>
                    <span class="serviceTable-item a-flex-1">充电时</span>
                    <span class="serviceTable-item a-flex-1">非充电时</span>
                </div>
                <div class="a-flex-rfsc a-w-100 a-fw-700" v-for="(item,index) in chargePlan.ruleList" :key="index">
                    <span class="serviceTable-item">{{ item.startTime == item.endTime ? '全天' : (item.startTime + '-' + item.endTime) }}</span>
                    <!-- <span class="serviceTable-item a-flex-1">按功率计费</span> -->
                    <span class="serviceTable-item a-flex-1">
                        <span v-if="item.feeRuleType==1 || item.feeRuleType==3">
                            {{ util.numFormat(item.serviceFee) }}元/
                            {{ item.serviceMinutes | formatMinute }}
                        </span>
                        <span v-else>
                            {{ util.numFormat(chargePlan.serviceBaseFee) }}元/
                            {{ chargePlan.serviceBaseTime | formatMinute }}
                        </span>
                    </span>
                    <span class="serviceTable-item a-flex-1">
                        <span v-if="item.feeRuleType==2 || item.feeRuleType==3">
                            {{ util.numFormat(item.serviceFee) }}元/
                            {{ item.serviceMinutes | formatMinute }}
                        </span>
                        <span v-else>
                            {{ util.numFormat(chargePlan.serviceBaseFee) }}元/
                            {{ chargePlan.serviceBaseTime | formatMinute }}
                        </span>
                    </span>
                </div>
                <div class="a-flex-rfsc a-w-100 a-fw-700" v-if="serviceDuration > 0 && serviceDuration < 24">
                    <span class="serviceTable-item">其余时间段</span>
                    <!-- <span class="serviceTable-item a-flex-1">按功率计费</span> -->
                    <span class="serviceTable-item a-flex-1">
                        {{ util.numFormat(chargePlan.serviceBaseFee) }}元/
                        {{ chargePlan.serviceBaseTime | formatMinute }}
                    </span>
                    <span class="serviceTable-item a-flex-1">
                        {{ util.numFormat(chargePlan.serviceBaseFee) }}元/
                        {{ chargePlan.serviceBaseTime | formatMinute }}
                    </span>
                </div>
                <div class="a-flex-rfsc a-w-100 a-fw-700" v-if="serviceDuration <= 0">
                    <span class="serviceTable-item">全天</span>
                    <!-- <span class="serviceTable-item a-flex-1">按功率计费</span> -->
                    <span class="serviceTable-item a-flex-1">
                        {{ util.numFormat(chargePlan.serviceBaseFee) }}元/
                        {{ chargePlan.serviceBaseTime | formatMinute }}
                    </span>
                    <span class="serviceTable-item a-flex-1">
                        {{ util.numFormat(chargePlan.serviceBaseFee) }}元/
                        {{ chargePlan.serviceBaseTime | formatMinute }}
                    </span>
                </div>
            </div>
            <span v-if="chargePlan.serviceBaseFee">服务费收取：不满{{ chargePlan.serviceBaseTime | formatMinute }}，按{{ chargePlan.serviceBaseTime | formatMinute }}计算</span>
        </div>
        <div class="content a-flex-rfsfs a-flex-wrap a-w-100" v-else>-</div>
        <div class="content a-flex-cfsfs a-flex-wrap a-w-100 a-c-666" v-if="chargePlan && chargePlan.needManagermentFee">
            <span class="a-mt-20 a-fw-700 a-c-333">管理费：</span>
            <span class="a-mt-20 a-fs-20 a-c-333 a-fw-700">{{ util.numFormat(chargePlan.managermentFee) }}元/{{ chargePlan.managermentMinutes }}分钟</span>
        </div>
    </div>
</template>

<script>
import util from '../../../utils/util'
export default {
    data() {
        return {
            util: util,
            typeDic: []
        };
    },
    props:['chargePlan'],
    mounted() {
        this.$getDic('chargingPlanType','select').then(res=>{ this.typeDic = res; })
    },
    computed:{
        serviceDuration () {
            // 特殊时间段总服务时长
            let duration = 0
            if(this.chargePlan.ruleList && this.chargePlan.ruleList.length){
                this.chargePlan.ruleList
                for (var i = 0; i < this.chargePlan.ruleList.length; i++) {
                    let item = this.chargePlan.ruleList[i]
                    if(item.startTime == item.endTime){ //开始时间 == 结束时间，则认为是全天时间段
                        return 24
                    }else{
                        let h = this.$getDay.getTimeDiff(item.startTime,item.endTime)
                        duration += h
                    }
                }
                return duration
            }else{
                return 0
            }
        }
    },
    filters:{
        formatMinute (val) {
            if(val % 60 == 0){
                return (val / 60).toFixed(0) + '小时'
            }else{
                return val + '分钟'
            }
        }
    },
    methods: {
        
    },
};
</script>

<style lang="scss" scoped>
.title {
    padding-top: 16px;
    span {
        font-weight: bold;
        &.dot {
            width: 6px;
            height: 6px;
            background: #797979;

        }
    }
}
.content{
    padding-left: 14px;
    padding-bottom: 8px;
}
.serviceTable{
    flex-direction: column !important;
    margin-bottom: 12px;
}
.serviceTable-item{
    padding: 6px 10px;
    background: #F5F7FA;
    border-radius: 2px;
    color: #606366;
    font-size: 14px;
    margin: 1px
}
.serviceTable-item:first-child{
    width: 180px;
}
</style>